<template>
  <div class="newsiteList">
    <div class="top">
      <img :src="siteBg" class="sitebg-img">
      <div class="top-site">
        <img :src="siteImg" class="siteImg-img">
        <div class="top-wrap">
          <div class="flex content-wrap">
            <div>
              <div class="flex">
                <div class="station_name">{{stationName}}</div>
                <div style="padding: 0.6rem 0 0 0.1rem"><van-icon name="edit" color="#3F7C53" size="0.4rem"/></div>
              </div>
              <p>所属部门：<span>{{deptName}}</span></p>
            </div>
            <div class="change_dept" @click="changeDeptClick">更换部门</div>
          </div>

          <img :src="investigation" class="investigation-img" v-if="stationStatusName == 'INSPECTION_'">
        </div>
      </div>
    </div>
    <div class="site-list">
      <div class="site-item flex" v-for="(item, index) in list" :key="index">
        <div class="flex site-item__left">
          <img :src="siteInfo" class="siteInfo-img" v-if="item.code == 'villageInfo'">
          <img :src="managerInfo" class="siteInfo-img" v-if="item.code == 'masterInfo'">
          <div class="site-content">
            <div>{{item.name}}</div>
            <p>{{item.status == 0? (item.code == 'villageInfo' ? '还未生成村情打分' : '还未生成站长打分'): '已完成可修改'}}</p>
          </div>
        </div>
        <van-button :class="{'finished': item.status == '1'}" @click="itemClick(item.code, item.hasSave, item.status)">{{ item.status == '1' ? '修改' : '去完成'}}</van-button>
      </div>
      <div class="site-item flex">
        <div class="flex site-item__left">
          <img :src="addInfo" class="siteInfo-img">
          <div class="site-content">
            <div>补充信息</div>
            <p>请先完成村情信息和站长信息</p>
          </div>
        </div>
        <van-button :disabled="disabled" @click="addInfoClick">去提交</van-button>
      </div>
    </div>

    <div v-if="overlayshow" class="overlay-class">
      <div class="deptlist patnerWarp">
        <van-radio-group v-model="result" class="content">
          <van-radio checked-color="#3F7C53"  :name="item.deptNo" v-for="(item, index) in deptList" :key="index">{{item.deptName}}</van-radio>
        </van-radio-group>
        <div class="publicBtnDiv">
          <van-button class="publicBtnActive" slot="button" type="info" color="#3F7C53" @click="buttonClick">完成</van-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getStationCaseByNo, getstationDeptList, updateStationDept } from '@/utils/api'
import common from '../../utils/common'
export default { 
  data() {
    return{
      siteBg:require('@/assets/img/site/siteBg.png'),
      siteImg: require('@/assets/img/site/siteImg.png'),
      siteInfo: require('@/assets/img/site/siteInfo.png'),
      managerInfo: require('@/assets/img/site/managerInfo.png'),
      addInfo: require('@/assets/img/site/addInfo.png'),
      investigation: require('@/assets/img/site/investigation.png'),
      disabled: false,
      finished: true,
      stationStatusName: '',
      stationName: '',
      areaNo: '',
      deptName: '',
      list:[],
      masterNo: '',
      overlayshow: false,
      deptList: [],
      result:'',
      stationDept: '',

    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.togetStationCaseByNo()
    })
  },
  methods:{
    buttonClick() {
      updateStationDept({
        stationNo: this.$route.query.stationNo,
        stationDept: this.result,
      }).then(res=>{
        this.overlayshow = false
        this.togetStationCaseByNo()
      })
      
    },
    changeDeptClick() {
      this.overlayshow = true
      getstationDeptList({
        stationNo: this.$route.query.stationNo
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.deptList = res.data.data
          }
         }
      })
    },
    togetStationCaseByNo() {
      getStationCaseByNo({stationNo: this.$route.query.stationNo}).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.stationName = res.data.data.stationName
            this.areaNo = res.data.data.areaNo
            this.deptName = res.data.data.deptName
            this.result = res.data.data.stationDept
            this.stationStatusName = res.data.data.stationStatusName
            this.masterNo = res.data.data.masterNo
            this.list = res.data.data.list

            common.setItem('stationNo', res.data.data.stationNo)

            for(let obj of this.list){
              if(obj.hasSave == 0){
                this.disabled = true
              }
            }

          }
         }
      })
    },
    itemClick(code, hasSave, status){
      if(code == 'villageInfo') this.siteInfoClick(hasSave, status)
      if(code == 'masterInfo') this.managerInfoClick(hasSave, status)
    },
    siteInfoClick(hasSave, status) {
      this.$router.push({name: 'newsiteInfo', query: {stationNo: this.$route.query.stationNo, hasSave: hasSave, status: status}})
    },
    managerInfoClick(hasSave, status) {
      this.$router.push({name: 'newmanagerInfo', query: {stationNo: this.$route.query.stationNo, hasSave: hasSave, masterNo: this.masterNo, status: status}})
    },
    addInfoClick() {
      this.$router.push({name: 'sitedetail', query: {stationNo: this.$route.query.stationNo}})
    },
  }
}
</script>

<style lang="less" scoped>
.newsiteList{
  background: #F7F8F9;
  min-height: 100%;
  .top{
    position: relative;
    color: #fff;
    .sitebg-img{
      width: 100%;
    }
    .siteImg-img{
      width: 100%;
    }
    .investigation-img{
      width: 1.4rem;
      // height: 0.5rem;
      position: absolute;
      left: 0.26rem;
      top: 0.07rem;
    }
    .top-site{
      margin: -1rem auto;
      z-index:10;
      position: relative;
      .top-wrap{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0 0.26rem;
      }
      .station_name{
        color: #333;
        font-size: 0.4rem;
        padding: 0.48rem 0 0 0.4rem;
      }
      .change_dept{
        color: #3F7C53;
        width: 1.44rem;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        background: rgba(63, 124, 83, 0.1);
        border-radius: 25px;
        font-size: 0.24rem;
        margin: 0.85rem 0.4rem 0.85rem 0.1rem;
      }
      p{
        color: #999;
        font-size: 0.26rem;
        margin: 0.2rem 0.4rem;
      }
      .content-wrap{
        // align-items: center;
        justify-content: space-between;
      }
    }
  }
  .siteInfo-img{
    width: 0.48rem;
    height: 0.48rem;
  }
  
  .site-list{
    margin-top: 1rem;
    .site-item{
      height: 1.34rem;
      background: #fff;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem;
      border-bottom: 1px solid #EEEEEE;;
    }
    .van-button{
      width: 1.2rem;
      height: 0.5rem;
      font-size: 0.24rem;
      background: #3F7C53;
      color: #fff;
      border-radius: 0.25rem;
    }
    .finished{
      background: #fff;
      color: #3F7C53;
      border: 1px solid #3F7C53;
    }
    .van-button--normal{
      padding: 0;
    }
    .site-item__left{
      align-items: center;
    }
    .site-content{
      margin-left: 0.2rem;
      margin-top: 0.2rem;
      div{
        color: #333;
        font-size: 0.3rem;
      }
      p{
        color: #999;
        font-size: 0.26rem;
      }
    }
  }
  .overlay-class{
    position: absolute;
    left: 0;
    top: 0;
    width:100%;
    min-height: 100%;
    z-index: 120;
    background: #f5f5f5;
  }
  .deptlist{
    height: 100%;
    padding-bottom: 50px;
    padding-top: 12px;
    background: #f5f5f5;
    overflow-y: auto;
    overflow-x: hidden;
    .van-overlay{
      z-index: 120;
    }
    .content{
      .van-radio{
        height: 1rem;
        padding: 0 0.3rem;
        border-bottom: 0.01rem solid #ddd;
        background: #fff;
      }
      .van-radio__icon{
        line-height: 1rem;
      }
    }
    .footer{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .van-button{
        width: 100%;
      }
    }
  }
}
</style>

<style>
  .patnerWarp .van-checkbox__label{
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
</style>